.summonerPage {
	color: white;
	margin: 20px 0px 0px 20px;
	display: flex;
}

.error {
	font-size: 32px;
	font-weight: bold;
}

.loading {
	display: flex;
	gap: 5px;
	align-items: center;
	font-size: 20px;
	font-weight: bold;
}

.card {
	background-color: #202d30;
	border-radius: 10px;
	width: fit-content;
	padding: 5px 10px;
	min-width: 350px;
}

.summonerProfile {
	display: flex;
	align-items: center;
	gap: 15px;
}

.summonerIcon {
	display: inline-block;
	position: relative;
}

.summonerProfile .profileIcon {
	border-radius: 50%;
	width: 100px;
	border: 3px solid #b20f3c;
}

.summonerProfile .level {
	background-color: #202d37;
	border-radius: 50%;
	padding: 0px 7px;
	font-size: 20px;
	border: 2px solid gray;
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, -100%);
}

.profileName {
	font-size: 32px;
	font-weight: bold;
}

.tag {
	margin-left: 5px;
	color: gray;
}

.summonerRank {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 20px;
}

.queue {
	font-size: 18px;
	font-weight: 600;
}

.separator {
	color: #b20f3c;
	font-size: 22px;
	margin-right: 10px;
}

.division {
	margin-top: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.division img {
	width: 64px;
	height: 64px;
}

.rankDivision {
	font-size: 24px;
	font-weight: bolder;
}

.rankStats {
	width: 260px;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.matchHistory {
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-left: 20px;
	background-color: #202d30;
	border-radius: 10px;
	padding: 10px 15px;
	width: 700px;
	margin-bottom: 20px;
}

.matchHistory > div:first-child {
	font-size: 18px;
	font-weight: 600;
	padding-bottom: 20px;
}

.chartsContainer {
	margin-top: 20px;
	width: 300px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.charts {
	margin: 10px 0px;
	display: flex;
	justify-content: center;
	gap: 20px;
}

.loadMore {
	margin: auto;
	margin-top: 5px;
}

.showMoreBtn {
	cursor: pointer;
	background-color: white;
	border: none;
	border-radius: 10px;
	color: #282c34;
	font-size: 16px;
	font-weight: bold;
	padding: 5px 10px;
}

.showMoreBtn:hover {
	background-color: gray;
	color: white;
	transition: all 0.2s ease;
}

.masteryContainer {
	margin-top: 20px;
}

.topMastery {
	display: flex;
	justify-content: space-around;
	margin-top: 10px;
	margin-bottom: 5px;
}

.champMastery {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.champName {
	text-align: center;
	font-weight: bold;
	font-size: 18px;
}

.masteryPoints {
	width: 64px;
	text-align: center;
	font-size: 14px;
}

.masteryProfile {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.masteryBanner {
	position: relative;
	width: 62px;
	height: 48px;
}

.bannerLevel {
	position: absolute;
	top: 0;
	left: 0;
	width: 58px;
	height: 48px;
}

.masteryLevel {
	position: absolute;
	top: 0;
	left: 2px;
	width: 54px;
	height: 44px;
}

.masteryPlate {
	position: relative;
	width: 50px;
	height: 22px;
	margin-top: -5px;
}

.masteryLevelPlate {
	width: 46px;
	height: 22px;
}

.champMasteryLevel {
	color: black;
	font-weight: bold;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin-top: -1px;
	margin-left: -2px;
}

.googleAds {
	display: flex;
	flex-direction: column;
}

@media (max-width: 980px) {
	.summonerPage {
		margin: 10px;
		flex-direction: column;
	}

	.summonerProfile .profileIcon {
		width: 80px;
	}

	.matchHistory {
		width: 95%;
		margin: 0;
		margin-top: 20px;
	}
}

@media (max-width: 480px) {
	.summonerPage {
		margin: 5px;
	}

	.card {
		min-width: fit-content;
		width: 95%;
	}

	.matchHistory {
		width: 95%;
		padding: 10px;
	}

	.chartsContainer {
		width: 100%;
	}
}
