.searchBar {
	width: 650px;
	padding-top: 50px;
	position: relative;
}

.searchBar input {
	width: calc(100% - 24px);
	height: 70px;
	font-size: 28px;
	border-radius: 15px;
	padding-left: 10px;
	padding-right: 10px;
	font-weight: 700;
	color: #282c34;
}

.searchBar input:focus {
	outline: 3px solid white;
}

.searchBar .searchButton {
	position: absolute;
	right: -3px;
	top: 37%;
	cursor: pointer;
	padding: 5px 10px;
	border-radius: 15px;
	background: black;
	border: 3px solid white;
}

.searchBar img {
	filter: invert(1);
}

.searchBar select {
	position: absolute;
	right: 15%;
	top: 54%;
	cursor: pointer;
	padding: 5px;
	border-radius: 20px;
	background: #b20f3c;
	color: white;
	font-size: 20px;
	font-weight: bold;
	border: 2px solid black;
	text-align: center;
	list-style-type: none;
}

.searchBar select option {
	font-weight: bold;
}

.searchBar select:focus {
	outline: 1px solid black;
}

.searchBar .disabledIcon {
	filter: contrast(0);
	cursor: default;
}

@media only screen and (max-width: 650px) {
	.searchBar {
		width: 450px;
	}

	.searchBar input {
		font-size: 21px;
	}

	.searchBar input:focus {
		outline: 2px solid white;
	}

	.searchBar select {
		font-size: 16px;
		right: 20%;
		top: 57%;
	}

	.searchBar .searchButton img {
		height: 60px;
	}

	.searchBar .searchButton {
		top: 39%;
	}
}

@media only screen and (max-width: 450px) {
	.searchBar {
		width: 320px;
	}

	.searchBar input {
		height: 50px;
		font-size: 18px;
	}

	.searchBar select {
		font-size: 14px;
		right: 18%;
		top: 58%;
	}

	.searchBar .searchButton img {
		height: 42px;
	}

	.searchBar .searchButton {
		top: 46%;
		padding: 5px;
	}
}
