.matchCard {
	width: 100%;
	height: 114px;
	background-color: gray;
	border-radius: 10px;
	padding: 5px 10px;
	box-sizing: border-box;
}

.winCard {
	background-color: rgba(8, 102, 255, 0.4);
}

.lossCard {
	background-color: rgba(255, 23, 8, 0.4);
}

.result {
	font-size: 12px;
	display: flex;
	align-items: center;
}

.winCard .result > span:first-child {
	font-weight: bold;
	color: #5797ff;
	margin-right: 5px;
	font-size: 16px;
}

.lossCard .result > span:first-child {
	font-weight: bold;
	color: #ff5e5e;
	margin-right: 5px;
	font-size: 16px;
}

.gameDuration {
	margin-left: 5px;
}

.matchFirstRow {
	display: flex;
	justify-content: space-between;
	padding-bottom: 8px;
}

.queueType {
	font-weight: bold;
	margin-right: 5px;
}

.timeAgo {
	font-size: 12px;
	font-weight: 400;
}

.matchSecondRow {
	display: flex;
	justify-content: space-between;
}

.mainIcons {
	display: flex;
}

.ownerChampion {
	position: relative;
}

.mainChamp {
	border: 1px solid white;
	border-radius: 10px;
	width: 64px;
	height: 64px;
	margin-right: 4px;
}

.champLevel {
	background-color: #282c34;
	font-weight: bold;
	font-size: 12px;
	border-radius: 10px;
	padding: 3px 5px;
	position: absolute;
	bottom: 6px;
	right: 3px;
}

.spellContainer {
	display: flex;
	flex-direction: column;
	gap: 4px;
	margin-right: 4px;
}

.spell {
	width: 28px;
	height: 28px;
	border-radius: 20%;
	border: 1px solid white;
}

.spellSecondary {
	width: 20px;
	height: 20px;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.primaryStats {
	display: flex;
	flex-direction: column;
	gap: 2px;
	color: gray;
	font-size: 14px;
}

.primaryStats .kda span {
	font-weight: bold;
	font-size: 20px;
	margin: 2px;
}

.primaryStats .kda span:first-child {
	margin-left: 0px;
}

.kills {
	color: #22cc00;
}

.deaths {
	color: #ff5454;
}

.assists {
	color: #d9bc00;
}

.kdaScore {
	color: white;
}

.kdaScore span {
	font-weight: bold;
	font-size: 16px;
}

.greatKda {
	color: #ffcc00;
}

.goodKda {
	color: #5797ff;
}

.greatKP {
	color: #ff6666;
}

.secondaryStats {
	display: flex;
	flex-direction: column;
	font-size: 12px;
}

.secondaryScore span {
	font-weight: bold;
	font-size: 14px;
}

.greatCS {
	color: #88ff6e;
}

.greatVision {
	color: #70a2ff;
}

.items {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	width: 130px;
}

.items img {
	width: 30px;
	height: 30px;
	border-radius: 10px;
	border: 1px solid white;
}

.items img:nth-child(1),
.items img:nth-child(2),
.items img:nth-child(3),
.items img:nth-child(7) {
	order: -1;
}

.cardGroup {
	display: flex;
}

.cardContent {
	width: 100%;
}

.toggleDetails {
	cursor: pointer;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	width: 35px;
	height: 108px;
	background-color: rgba(0, 0, 0, 0.2);
	margin-top: -5px;
	margin-right: -10px;
	margin-left: 15px;
	border-radius: 0px 10px 10px 0px;
	padding-bottom: 6px;
}

.toggleDetails:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.sigma {
	margin-left: 5px;
	border-radius: 12px;
	font-weight: bold;
	padding: 1px 10px 4px 10px;
	background-color: #8a0000;
}

.alpha {
	margin-left: 5px;
	border-radius: 12px;
	font-weight: bold;
	padding: 1px 10px 4px 10px;
	background-color: #003c96;
}

.additionalDetails {
	background-color: #182629;
	padding: 2px 20px 5px 20px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 2px;
}

.matchResult {
	margin: 3px;
}

.victory {
	color: #1f80ff;
	font-weight: 600;
	font-size: 18px;
}

.defeat {
	color: #ff1414;
	font-weight: 600;
	font-size: 18px;
}

.participantData {
	border-radius: 10px;
	padding: 5px;
}

.participantData .matchSecondRow {
	height: 72px;
}

.ownerWin {
	background-color: #004cbf;
}

.participantWin {
	background-color: #003c96;
}

.ownerLose {
	background-color: #8a0000;
}

.participantLose {
	background-color: #700000;
}

.participantInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2px;
	margin-left: 3px;
	height: 68px;
	width: 130px;
	font-weight: 600;
}

.participantName {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-top: -5px;
}

.participantName a {
	text-decoration: none;
	color: white;
}

.participantElo {
	color: darkgray;
}

.participantInfo > span .sigma {
	margin: 0;
	padding: 1px 7px 4px 7px;
	font-size: 14px;
}

.participantInfo > span .alpha {
	margin: 0;
	padding: 1px 7px 4px 7px;
	font-size: 14px;
}

@media (max-width: 1178px) {
	.matchCard {
		padding: 5px;
	}

	.queueType,
	.timeAgo,
	.gameDuration {
		font-size: 11px;
	}

	.result {
		font-size: 11px;
	}

	.winCard .result > span:first-child {
		font-size: 12px;
	}

	.lossCard .result > span:first-child {
		font-size: 12px;
	}

	.spellSecondary {
		width: 18px;
		height: 18px;
	}

	.primaryStats {
		font-size: 13px;
		gap: 2px;
	}

	.primaryStats .kda span {
		font-size: 16px;
	}

	.kdaScore span {
		font-size: 14px;
	}

	.secondaryScore span {
		font-size: 12px;
	}

	.secondaryStats {
		font-size: 11px;
	}

	.sigma {
		font-size: 12px;
	}

	.alpha {
		font-size: 12px;
	}

	.items img {
		width: 22px;
		height: 22px;
		border-radius: 7px;
	}

	.additionalDetails {
		padding: 5px;
	}

	.participantInfo {
		font-size: 14px;
		width: 75px;
	}

	.participantInfo > span .sigma {
		font-size: 12px;
	}

	.participantInfo > span .alpha {
		font-size: 12px;
	}
}

@media (max-width: 580px) {
	.matchCard {
		padding: 5px;
	}

	.queueType,
	.timeAgo,
	.gameDuration {
		font-size: 10px;
	}

	.result {
		font-size: 10px;
	}

	.mainChamp {
		width: 56px;
		height: 56px;
	}

	.champLevel {
		font-size: 10px;
		padding: 2px 5px;
	}

	.spell {
		width: 22px;
		height: 22px;
	}

	.spellSecondary {
		width: 18px;
		height: 18px;
	}

	.spellContainer {
		margin-right: 3px;
	}

	.primaryStats {
		font-size: 10px;
		gap: 1px;
	}

	.primaryStats .kda span {
		font-size: 11px;
	}

	.kdaScore span {
		font-size: 11px;
	}

	.secondaryScore span {
		font-size: 11px;
	}

	.secondaryStats {
		font-size: 10px;
	}

	.secondaryStats div {
		width: 60px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.items img {
		width: 18px;
		height: 18px;
		border-radius: 7px;
	}

	.winCard .result > span:first-child {
		font-size: 11px;
	}

	.lossCard .result > span:first-child {
		font-size: 11px;
	}

	.toggleDetails {
		margin-left: -10px;
	}

	.participantInfo {
		font-size: 12px;
		width: 50px;
	}

	.sigma {
		font-size: 11px;
	}

	.alpha {
		font-size: 11px;
	}

	.participantInfo > span .sigma {
		font-size: 11px;
	}

	.participantInfo > span .alpha {
		font-size: 11px;
	}

	.additionalDetails {
		padding: 5px;
	}

	.participantData {
		height: 82px;
	}
}
