.mainHeader {
	width: calc(100% - 20px);
	background-color: #282c34;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 10px;
}

.mainHeader a {
	text-decoration: none;
	color: white;
}

.logoContainer {
	display: flex;
	align-items: center;
	gap: 10px;
}

.searchIcon {
	filter: invert(1);
}
