.spinner {
	position: relative;
	width: 50px;
	height: 50px;
}

.spinner-inner {
	position: absolute;
	border-top: 5px solid #b20f3c;
	border-right: 5px solid transparent;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	top: 8px;
	left: 8px;
	animation: spin-inner 0.7s linear infinite;
}

@keyframes spin-inner {
	100% {
		transform: rotate(360deg);
	}
}
