.page {
	min-height: 100vh;
	background-color: #001a03;
	background-image: url('/public/images/hero.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	box-shadow: 0 0 300px black inset;
}

.content {
	position: absolute;
	top: 50%;
	right: 50%;
	transform: translate(50%, -50%);
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.title {
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 36px;
}

.description {
	font-size: 28px;
	font-style: italic;
	font-weight: 700;
}

@media only screen and (max-width: 650px) {
	.title {
		font-size: 30px;
	}
	.description {
		font-size: 24px;
	}
}

@media only screen and (max-width: 450px) {
	.title {
		font-size: 24px;
	}
	.description {
		font-size: 18px;
	}
}
